<template>
  <div class="wrapper">
    <admin-title :title="$route.meta.name"></admin-title>
    <el-form ref="form" :model="formData" label-width="100px">
      <el-form-item label="充值金额" prop="stored_value_amount" :rules="rules">
        <el-input-number
          style="width: 300px"
          v-model="formData.stored_value_amount"
          placeholder="请输入"
          :step="0.01"
          size="small"
          :precision="2"
          step-strictly
          :min="0"
        ></el-input-number>
      </el-form-item>
      <!-- <el-form-item label="赠送金额">
        <el-input-number
          style="width: 300px"
          v-model="formData.gifted_amount"
          placeholder="请输入"
          :step="1"
          size="small"
          :precision="2"
          step-strictly
          :min="0"
        ></el-input-number>
      </el-form-item> -->

      <el-form-item label="">
        <el-button type="primary" size="small" @click="save">保存</el-button>
        <el-button type="" size="small" @click="$router.back()">取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { getDetailAPI, addAPI, editAPI } from './api'

export default {
  name: 'AddOrEdit',

  data() {
    return {
      rules: [{ required: true, message: '该项不能为空', trigger: 'blur' }],
      formData: {
        stored_value_amount: undefined,
        gifted_amount: undefined
      }
    }
  },

  mounted() {
    if (this.$route.params.id) this.getDetail()
  },

  methods: {
    save() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.formData.id) {
            editAPI(this.formData).then(() => {
              this.$message.success('编辑成功')
              this.$router.back()
            })
          } else {
            addAPI(this.formData).then(() => {
              this.$message.success('添加成功')
              this.$router.back()
            })
          }
        }
      })
    },
    async getDetail() {
      this.formData = await getDetailAPI(this.$route.params.id)
    }
  }
}
</script>

<style lang="scss" scoped></style>
